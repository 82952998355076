<template>
  <div>
    <search-tool :title="$t('organizationList')" @search="search" @refresh="getList(currentPage)">
      <template #handle-buttons>
        <el-button size="small" type="primary" @click="$router.push('/admin/organization/add')">
          {{ $t("handle.add") }}
        </el-button>
      </template>
    </search-tool>

    <el-table :data="data" v-loading="loading">
      <el-table-column prop="name" :label="$t('name')" width="500">
        <template slot-scope="scope">
          <div class="table-name-info">
            <svg-icon icon-class="company"></svg-icon>
            <div class="content">
              <div class="title">{{ scope.row.name }}</div>
              <span class="desc">
                <i class="el-icon-document-copy" v-clipboard:copy="scope.row.uuid"></i>
                {{ scope.row.uuid }}
              </span>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="cnName" :label="$t('organizationAlias')"> </el-table-column>
      <el-table-column prop="contact" :label="$t('contacter')"> </el-table-column>
      <el-table-column prop="email" :label="$t('contacterEmail')"> </el-table-column>

      <el-table-column width="100" :label="$t('handle.handle')">
        <template slot-scope="scope">
          <el-dropdown trigger="click">
            <button class="table-row-handle">
              <i class="el-icon-more-outline"></i>
            </button>

            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item>
                <div @click="createInviteInfo(scope.row)">
                  {{ $t("handle.generateInvitationInfo") }}
                </div>
              </el-dropdown-item>

              <el-dropdown-item>
                <div @click="edit(scope.row)">
                  {{ $t("handle.edit") }}
                </div>
              </el-dropdown-item>

              <el-dropdown-item>
                <div @click="deleteOrganization(scope.row)">
                  {{ $t("handle.delete") }}
                </div>
              </el-dropdown-item> -->

              <el-dropdown-item>
                <div @click="monitorEdit(scope.row)">
                  Thanos设置
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="Thanos设置" :visible.sync="monitorVisible">
      <div style="margin-bottom: 10px;">
        <div class="list-title">
          <div class="title">
            Thanos设置
          </div>

          <div class="desc">
            多租户情况下，使用Thanos Receiver 架构需要标明监控数据的租户信息，数据来源于 receiver 组件的
            default-tenant-id 或者 tenant-label-name
          </div>
        </div>
      </div>

      <el-form ref="monitorForm" :model="monitorForm" size="small">
        <el-form-item label="唯一性标示 Key">
          <el-input v-model="monitorForm.monitorKey" placeholder="default-tenant-id"></el-input>
        </el-form-item>

        <el-form-item label="唯一性标示 Value">
          <el-input v-model="monitorForm.monitorValue" placeholder="tenant_name"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="primary" @click="monitorSubmit" size="small" :loading="monitorSubmitLoading">
          {{ $t("handle.submit") }}
        </el-button>
      </span>
    </el-dialog>

    <el-dialog :title="$t('handle.edit')" :visible.sync="dialogVisible" top="30px">
      <organization-form :form="form" :editable="false" ref="form" v-if="dialogVisible" />
      <span slot="footer">
        <el-button type="primary" @click="submit" size="small" :loading="submitLoading">
          {{ $t("handle.submit") }}
        </el-button>
      </span>
    </el-dialog>

    <el-dialog :title="$t('invitationInfo')" :visible.sync="inviteDialogVisible">
      <div class="invite-container">
        <div class="invite-info">{{ $t("organizationID") }}</div>
        <div class="invite-value">
          <i class="el-icon-document-copy" v-clipboard:copy="joinOrganizationID"></i>
          {{ joinOrganizationID }}
        </div>
      </div>

      <div class="invite-container">
        <div class="invite-info">{{ $t("inviterID") }}</div>
        <div class="invite-value">
          <i class="el-icon-document-copy" v-clipboard:copy="inviter"></i>
          {{ inviter }}
        </div>
      </div>

      <div class="invite-container">
        <div class="invite-info">{{ $t("invitationCode") }}</div>
        <div class="invite-value">
          <i class="el-icon-document-copy" v-clipboard:copy="invitationCode"></i>
          {{ invitationCode }}
        </div>
      </div>

      <div class="invite-container">
        <el-button size="small" type="primary" @click="copyInviteInfo">
          {{ $t("handle.copy") }}
        </el-button>
      </div>
    </el-dialog>

    <el-pagination
      layout=" prev, pager, next, sizes, total"
      :total="total"
      :page-size="currentPageSize"
      :page-sizes="[10, 15, 20, 30, 50]"
      :current-page="currentPage"
      @current-change="currentChange"
      @size-change="sizeChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { organizationList, organizationEdit, organizationDelete } from "api/organization";
import OrganizationForm from "./Form.vue";
import { cloneDeep } from "lodash";

export default {
  components: {
    OrganizationForm
  },

  data() {
    return {
      data: [],
      total: undefined,
      loading: false,
      currentPage: 1,
      currentPageSize: 10,
      searchText: "",
      form: {},
      dialogVisible: false,
      submitLoading: false,

      inviteDialogVisible: false,

      joinOrganizationID: "",
      inviter: "",
      invitationCode: "",

      monitorVisible: false,
      monitorSubmitLoading: false,
      monitorForm: {}
    };
  },

  methods: {
    getList(page = 1, pageSize = this.currentPageSize, search = [this.searchText]) {
      this.loading = true;
      this.currentPage = page;

      organizationList(this.userId, { page, pageSize, search }).then(response => {
        if (response.code === 0) {
          this.loading = false;
          this.data = response.data.items;
          this.total = response.data.total;
        }
      });
    },

    search(searchText) {
      this.searchText = searchText;
      this.getList(1);
    },

    currentChange(page) {
      this.currentPage = page;
      this.getList(page);
    },

    sizeChange(size) {
      this.currentPageSize = size;
      this.getList(1, size);
    },

    edit(row) {
      this.form = cloneDeep(row);
      this.dialogVisible = true;
    },

    monitorEdit(row) {
      this.monitorForm = cloneDeep(row);

      if (!this.monitorForm.monitorKey) this.$set(this.monitorForm, "monitorKey", "");
      if (!this.monitorForm.monitorValue) this.$set(this.monitorForm, "monitorValue", "");

      this.monitorVisible = true;
    },

    monitorSubmit() {
      this.$refs["monitorForm"].validate(valid => {
        if (valid) {
          this.monitorSubmitLoading = true;

          organizationEdit(this.monitorForm).then(response => {
            this.monitorSubmitLoading = false;
            if (response.code === 0) {
              this.getList(this.currentPage);
              this.monitorVisible = false;
            }
          });
        }
      });
    },

    submit() {
      this.$refs["form"].$refs["form"].validate(valid => {
        if (valid) {
          this.submitLoading = true;
          organizationEdit(this.form).then(response => {
            this.submitLoading = false;
            if (response.code === 0) {
              this.getList(this.currentPage);
              this.dialogVisible = false;
            }
          });
        }
      });
    },

    deleteOrganization(item) {
      this.$confirm(this.$t("delete", [item.name]), this.$t("prompt"), {
        type: "warning"
      })
        .then(() => {
          organizationDelete(item.uuid).then(response => {
            if (response.code === 0) {
              item.uuid == this.organization ? this.deleteCurrent() : this.getList(this.currentPage);
            }
          });
        })
        .catch(() => {});
    },

    deleteCurrent() {
      this.$router.push({
        path: "/home"
      });

      window.location.reload();
    },

    createInviteInfo(row) {
      this.inviteDialogVisible = true;

      this.joinOrganizationID = row.uuid;
      this.inviter = this.userId;
      this.invitationCode = row.invitationCode;
    },

    copyInviteInfo() {
      let inviteMessage = `
        ${this.$t("organizationID")}: ${this.joinOrganizationID}
        ${this.$t("inviterID")}: ${this.inviter}
        ${this.$t("invitationCode")}: ${this.invitationCode}
      `;
      this.$copyText(inviteMessage).then(
        () => {
          this.$notify({
            title: "Success",
            message: this.$t("copyInvitationInfo"),
            type: "success"
          });
        },
        () => {
          this.$message.error("Error");
        }
      );
    }
  },

  mounted() {
    this.getList();
  },

  computed: {
    userId() {
      return this.$store.state.app.userId;
    },

    organization() {
      return this.$store.state.app.organization;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.invite-container {
  padding: 10px;

  .invite-info {
    font-size: 12px;
    color: $color-sub;
  }

  .invite-value {
    padding: 10px 6px;
    background-color: #fafbfc;
    @include title(12px);
  }
}
</style>
